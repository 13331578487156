import React from 'react'
import IndexPage from '../components/pages/Index'
import Layout from '../Layouts/zh-hk'

const index = ({ location }) => {
  return (
    <Layout location={location}>
      <IndexPage />
    </Layout>
  )
}

export default index
